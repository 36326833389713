.chat-support-page {
  @include brSpPortrait {
    height: calc(100vh - 165px) !important;
    overflow-y: hidden;

    &-chat-list {
      height: min-content !important;
    }

    & > .ant-card {
      height: 100%;
      margin-bottom: 0;
      padding-bottom: 100px;

      & > .ant-card-body {
        height: 100%;
      }

      & .chat-support {
        height: 100%;
      }

      & .chat-support-chat-list {
        height: 66vh;

        & textarea {
          font-size: 16px;
        }
      }

      & .ant-tabs {
        & > .ant-tabs-nav {
          top: 75px;
        }

        & .gx-chat-user {
          height: calc(100vh - 200px);
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  & .ant-tabs > .ant-tabs-nav {
    position: fixed;
    height: 42px;
    z-index: 10;
    background: #fff;
  }

  & .gx-chat-user {
    margin-top: 58px;
  }
}
