.header {
  @include brSpPortrait {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    & > .gx-linebar {
      margin-right: 0 !important;

      & > .icon-menu {
        width: 32px;
        height: 32px;
      }
    }

    & > h2 {
      font-size: 20px !important;
      margin: 0 !important;
    }

    & > .gx-avatar-row {
      & > span {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;

        & .ant-avatar {
          height: 32px !important;
          width: 32px !important;
          margin-right: 0 !important;
        }

        & .icon-chevron-down {
          margin-left: 0 !important;
        }
      }
    }
  }
}
