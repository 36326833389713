@for $i from 0 through 1000 {

  // margin top: m-t-10 --> margin-top: 10px;
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }

  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }

  .m-r-#{$i} {
    margin-right: #{$i}px !important;
  }

  .m-#{$i} {
    margin: #{$i}px !important;
  }

  .m-t-b-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }

  .m-l-r-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }

  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }

  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .p-l-#{$i} {
    padding-left: #{$i}px !important;
  }

  .p-r-#{$i} {
    padding-right: #{$i}px !important;
  }

  .p-#{$i} {
    padding: #{$i}px !important;
  }

  .p-t-b-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .p-l-r-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }

  .m-t-#{$i}-n {
    margin-top: -#{$i}px !important;
  }

  .m-b-#{$i}-n {
    margin-bottom: -#{$i}px !important;
  }

  .m-l-#{$i}-n {
    margin-left: -#{$i}px !important;
  }

  .m-r-#{$i}-n {
    margin-right: -#{$i}px !important;
  }

  .m-#{$i}-n {
    margin: -#{$i}px !important;
  }

  .m-t-b-#{$i}-n {
    margin-top: -#{$i}px !important;
    margin-bottom: -#{$i}px !important;
  }

  .m-l-r-#{$i}-n {
    margin-left: -#{$i}px !important;
    margin-right: -#{$i}px !important;
  }

  .width-#{$i} {
    width: #{$i}px !important;
  }

  .height-#{$i} {
    height: #{$i}px !important;
  }

  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }

  .top-#{$i} {
    top: #{$i}px !important;
  }

  .bottom-#{$i} {
    bottom: #{$i}px !important;
  }

  .right-#{$i} {
    right: #{$i}px !important;
  }

  .left-#{$i} {
    left: #{$i}px !important;
  }

  .top-#{$i}-n {
    top: -#{$i}px !important;
  }

  .bottom-#{$i}-n {
    bottom: -#{$i}px !important;
  }

  .right-#{$i}-n {
    right: -#{$i}px !important;
  }

  .left-#{$i}-n {
    left: -#{$i}px !important;
  }

  // line-height
  .line-height-#{$i} {
    line-height: #{$i}px !important;
  }

  //letter-spacing
  .letter-spacing-#{$i} {
    letter-spacing: #{$i}px;
  }

  .letter-spacing-#{$i}-n {
    letter-spacing: -#{$i}px;
  }
}

@for $i from 0 through 10 {
  .line-height-#{$i}-large {
    line-height: #{$i} !important;
  }
}

.d-none {
  display: none !important;
  ;
}

.width-auto {
  width: auto !important;
}

.height-auto {
  height: auto !important;
}

.width-100-vw {
  width: 100vw !important;
}

.height-100-vh {
  height: 100vh !important;
}

.width-100-pc {
  width: 100% !important;
}

.width-95-pc {
  width: 95% !important;
}

.width-90-pc {
  width: 90% !important;
}

.width-85-pc {
  width: 85% !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-100 {
  z-index: 100 !important;
}

.z-index-1000 {
  z-index: 1000 !important;
}

.z-index-10000 {
  z-index: 10000 !important;
}

.z-index-100000 {
  z-index: 100000 !important;
}

.m-t-b-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-t-auto {
  margin-top: auto !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.m-l-r-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-auto {
  margin: auto !important;
}

.word-break {
  word-break: break-all;
}

.overflow-hidden {
  overflow: hidden;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
body {
  font-family: "Noto Sans JP", sans-serif;
}

// drag table
tr.drop-over-downward td {
  border-bottom: 2px dashed #e25114;
}

tr.drop-over-upward td {
  border-top: 2px dashed #e25114;
}

.gx-app-login-wrap {
  .gx-app-login-header {
    height: 50px;
    margin-bottom: 0px;
    background-color: #93258a;

  }

  .gx-app-login-main-content {
    height: 60%;
    display: flex;
    align-items: center;

    @media (max-width: 575px) {
      height: auto;
    }

    .title {
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 1rem;
    }

    .gx-signin-form.gx-form-row0 {
      width: 100%;
    }

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
}

.remove-item-table {
  color: #93258a;
  cursor: pointer;
}

.gx-main-wrapper {

  .gx-main-content-wrapper {

    .gx-main-wrapper {
      .row {}
    }
  }

  .ant-card.ant-card-bordered {
    .ant-card-head {
      margin: 0;
    }

    .ant-card-body {
      .ant-form-item-control-input-content {
        label {
          margin: 10px;
        }

        .lable-required::after {
          content: "*";
          color: #dc3545;
          padding-left: 5px;
        }
      }

      .ant-row {
        margin: 0 0 10px 0;
        gap: 10px;

        .ant-form-item {
          width: calc(50% - 5px);
          flex-direction: column;

          .ant-col.ant-form-item-label {
            text-align: start;

            .ant-form-item-required:after {
              content: "*";
              color: #dc3545;
              padding-left: 5px;
            }
          }
        }
      }

      .submit-create {
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: center;

          button {
            width: 20%;
          }
        }
      }
    }
  }
}

.gx-app-no-permission {
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  img {
    object-fit: fill;
    height: 100vh;
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

#role-management {
  .kt-section {
    .btn-update-role {
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.kt-section__content {
  display: block;
  padding: 0;
  margin: 0;

  .search-bar {
    .search-lable {
      height: 50px;
      display: flex;
      align-items: center;

      .lable-search {
        font-weight: bold;
        font-size: 25px;
      }
    }

    .ant-table {
      border: 1px solid #e8e8e8;
      border-bottom: none;
    }

    .form-row {
      display: flex;
      gap: 5px;

      @media (max-width: 1199px) {
        flex-wrap: wrap;

        & > .col-md-7,
        & > .col-md-5, 
        & > .col-md-8,
        & > .col-md-4 {
          width: 100%;
        }

        & > .col-md-4 {
          & > .form-group {
            margin-bottom: 0.5rem;
          }
        }
      }

      .header-button-create {
        background-color: rgba(146, 37, 138, .3);
        color: rgba(146, 37, 138);
        padding: 0;
        text-align: center;
      }

      .header-button-create:hover {
        background-color: rgba(146, 37, 138);
        color: #fff;
      }

      .header-button {
        background-color: rgba(88, 103, 221, .1);
        color: #5867dd;
        padding: 0;
        text-align: center;
      }

      .header-button:hover {
        background-color: #5867dd;
        color: #fff;
      }

      .form-group {
        gap: 5px;
      }

      .form-control {
        height: 38px;
      }

      .kt-margin-l-5 {
        margin-right: 0;
      }
    }
  }

  &.kt-section__content--border {
    padding: 1.5rem;
    border: 3px solid kt-base-color(grey, 2);

    @include kt-rounded {
      border-radius: $kt-border-radius;
    }
  }

  &.kt-section__content--solid {
    padding: 1.5rem;
    border-left: 4px solid kt-base-color(grey, 3);
    background-color: kt-base-color(grey, 1);

    @include kt-rounded {
      border-radius-top-left: $kt-border-radius;
      border-radius-top-right: $kt-border-radius;
    }
  }

  &.kt-section__content--x-fit {
    padding-left: 0;
    padding-right: 0;
  }

  &.kt-section__content--fit {
    padding: 0;
  }
}

.label-status {
  &_active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border-radius: 10px;
    width: 70px;
    background-color: rgba(113, 255, 184, .8);
    padding-bottom: 4px;
  }

  &_blocked {
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 25px;
    border-radius: 10px;
    width: 70px;
    background-color: rgba(250, 87, 81, .9);
    padding-bottom: 1px;
  }
}

.row-loading {
  align-items: center;
}

.center-all {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.alert-nocontent-message {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 12px;
      padding-bottom: 14px;
    }
  }
}

.member-only-notification {
  justify-content: flex-start;
  width: fit-content;
  align-items: center;

  .ant-row.ant-form-item {
    width: fit-content;
    flex-direction: row-reverse !important;

    .ant-form-item-label>label {
      height: 39px;
    }
  }
}