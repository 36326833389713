.sidebar {
  @include brSpPortrait {
    height: calc(100vh - 72px) !important;

    &-logo {
      display: none;
    }
  }

  & > div {
    overflow: hidden !important;

    & > .ant-menu {
      & > .ant-menu-item {
        padding-left: 30px !important;
      }
    }
  }

  &-logo {
    @include brSpPortrait {
      display: none;
    }
  }
}
