.row-chat-support {
  .ant-card {
    padding: 20px 32px 0px !important;

    @media (max-width: 555px) {
      padding: 0 !important;
      // margin-bottom: 100px;
    }

    .ant-card-body {
      padding-bottom: 0px !important;
    }
  }

  .chat-support {
    display: flex;
    gap: 5px;
    height: 80vh;

    &_user-chat-list {
      width: 30%;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .gx-chat-user {
        .gx-chat-user-item {
          border: solid 1px #e8e8e8 !important;
          border-radius: 5px;

          .ant-popover {
            z-index: 1;
          }
        }

        column-gap: 10px;
        row-gap: 10px;
      }

      .gx-chat-resolved {
        & > .anticon {
          & > svg {
            width: 20px;
            height: 20px;
          }

          &:hover {
            & > svg {
              transform: scale(1.3);
            }
          }
        }
      }
    }

    &_user-chat-content {
      background-color: #f6eae2;
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;

      &-back {
        height: 50px;
        position: fixed;
        right: 0;
        left: 0;
        top: 50px;
        z-index: 10;
        background: #fff;
        box-shadow: 0 4px 4px hsla(0, 0%, 74%, 0.25);
        display: flex;
        align-items: center;
        padding-left: 10px;
        gap: 10px;

        & > span {
          font-weight: 600;
          font-size: 16px;
        }
      }

      .conversion {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: auto;

        &-receive {
          align-items: start;
        }

        &-sent {
          align-items: flex-end;
        }
      }

      .conversion-message {
        text-align: right;
      }

      .conversion-image {
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
        max-width: 355px;
      }

      .conversion-time {
        margin: 0 16px;
      }

      textarea {
        min-height: 55px;
        border-radius: 0;
      }

      .gx-chat-main-content {
        display: flex;
        flex-direction: column-reverse;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .send-message {
        background: #fff;

        .send_textarea {
          width: 100%;

          & .ant-form-item,
          & .ant-row,
          & .ant-form-item-control-input {
            width: 100%;
          }

          & .ant-row {
            margin-left: 0;
          }

          & > .ant-form-item {
            margin-bottom: 0;
          }
        }

        .send-button {
          .p-0 {
            padding: 0;
          }
          .btn-send-image {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            & svg {
              width: 24px;
              height: 24px;
            }
          }
          .btn-action {
            height: 100%;
            width: 100%;

            button {
              border-left: unset;
              background: $kt-primary-color;
              border-radius: 0;
              margin-bottom: 0px;
              font-weight: 600;
              font-size: 18px;
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .show-image {
        margin: 10px;
        display: flex;
        gap: 15px;
        flex-flow: row nowrap;

        &-item {
          position: relative;
        }

        &-delete {
          position: absolute;
          z-index: 2;
          top: -10px;
          right: -8px;
          background: transparent;
          padding: 0;
          margin: 0;
          height: auto;
          line-height: normal;
          border: none;

          & img {
            background-color: transparent;
          }
        }
      }

      .ant-form-horizontal .ant-row {
        margin-bottom: 0px;
        margin-right: 0px;
      }

      .ant-input-group {
        display: flex;

        img {
          width: 30px;
        }

        .ant-col {
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-row.ant-form-item {
            width: 100%;

            .ant-form-item-control-input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
