.word-break {
  word-break: normal;
  white-space: normal;
  line-height: 1.2 !important;
}

.btn-custom {
  padding: 0;
  text-align: center;
}

.card {
  @include brSpPortrait {
    padding: 0 !important;
    height: calc(100vh - 145px);
    overflow-y: auto;
  }
}

.btn-sp {
  @include brSpPortrait {
    padding: 0;
    text-align: center;
    width: 84px !important;
  }
}

@for $i from 0 through 4000 {
  .width-#{$i} {
    width: #{$i}px !important;

    & > .ant-tabs-nav {
      width: #{$i}px !important;
    }
  }
  .height-#{$i} {
    height: #{$i}px !important;
  }
  .min-width-#{$i} {
    min-width: #{$i}px !important;
  }
  .min-height-#{$i} {
    min-height: #{$i}px !important;
  }
  .max-width-#{$i} {
    max-width: #{$i}px !important;
  }
  .max-height-#{$i} {
    max-height: #{$i}px !important;
  }
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  .ant-table-cell {
    padding: unset;

    .content-question {
      height: 5vh;
      display: flex;
      align-items: center;
      margin: 12px;
    }
  }
}

.btn-ordinal{
  float: right;
  margin: 0px 0px 15px 0px !important;
  background-color: rgba(146, 37, 138, 0.3);
  color: rgb(146, 37, 138);
  text-align: center;
}